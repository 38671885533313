<template>
  <div>
    <AppModal
      v-model="model"
      :class="animationClass"
      :title="$t('ComponentAddPaymentBankModal.TitleModal').value"
    >
      <template #header="{ dismiss }">
        <AppModalHeader>
          <template #left>
            <AppBackButton
              name="Go Back"
              icon="<"
              analytics-name="add-payment-bank-modal-back"
              @click="dismiss"
            />
          </template>

          <h1>{{ $t('ComponentAddPaymentBankModal.Header').value }}</h1>

          <template #right>
            <AppBackButton
              analytics-name="add-payment-bank-modal-back-right"
              @click="$router.toParentRoute()"
            />
          </template>
        </AppModalHeader>
      </template>

      <AppList>
        <!-- Add Bank Plaid -->
        <RiaListItem
          :title="$t('ComponentAddPaymentBankModal.ButtonInstantVerification').value"
          analytics-name="add-payment-bank-plaid"
          @itemClick="showPlaid"
        >
          <template #figure-icon>
            <IconUserAdmin />
          </template>
        </RiaListItem>

        <!-- Add Bank Manual -->
        <RiaListItem
          :title="$t('ComponentAddPaymentBankModal.ButtonManualVerification').value"
          :sub-title="$t('ComponentAddPaymentBankModal.ButtonManualVerificationDescription').value"
          analytics-name="add-payment-bank-manual-verification"
          @itemClick="manualCreate"
        >
          <template #figure-icon>
            <IconUserProfile />
          </template>
        </RiaListItem>
      </AppList>
    </AppModal>
    <AddBankAccountPlaidModal
      v-if="isPlaidInFlow"
      @event="onEvent"
      @success="onPlaidSuccess"
      @exit="onPlaidExit"
    />
    <AppOverlay :value="addBankAccountPlaid.loading">
      <AppSpinnerBig :loading="addBankAccountPlaid.loading"></AppSpinnerBig>
    </AppOverlay>
    <BankPaymentLimitReached v-model="showLimitReached"></BankPaymentLimitReached>
  </div>
</template>

<script>
import { reactive, ref } from '@vue/composition-api'
import { usePromiseLazy } from 'vue-composable'

import { useRouter, onBeforeRouteLeave } from '@galileo/composables/useRouter'
import AddBankAccountPlaidModal from '@galileo/components/Views/PaymentMethod/AddBankAccountPlaidModal'
import BankPaymentLimitReached from '@galileo/components/Views/PaymentMethod/BankPaymentLimitReached'
import RiaListItem from '@galileo/components/RiaListItem/RiaListItem'
import { SEGMENT_EVENTS } from '@galileo/constants/segmentAnalytics'

import {
  useAppStore,
  useSendMoneyStore,
  useI18nStore,
  useAnalyticsStore,
  useAuthStore,
  useBankAccountStore,
} from '@galileo/stores'

import { IconUserAdmin, IconUserProfile } from '@oen.web.vue2/icons'

import {
  useAlert,
  AppModal,
  AppModalHeader,
  AppList,
  AppBackButton,
  AppOverlay,
  AppSpinnerBig,
  useMediaQuery,
  useTransition,
  useVModel,
} from '@oen.web.vue2/ui'

import { STEPS } from '@galileo/constants/sendMoneyFlow.const'

export default {
  name: 'PaymentBankModal',
  components: {
    AddBankAccountPlaidModal,
    BankPaymentLimitReached,
    AppModalHeader,
    AppList,
    AppModal,
    IconUserAdmin,
    IconUserProfile,
    AppBackButton,
    AppOverlay,
    AppSpinnerBig,
    RiaListItem,
  },
  emits: ['input'],
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },

  setup(props, { emit }) {
    const router = useRouter()
    const { $t } = useI18nStore()
    const { model } = useVModel(props, emit)

    const appStore = useAppStore()
    const analyticsStore = useAnalyticsStore()
    const authStore = useAuthStore()
    const alerter = useAlert()
    const bankAccountStore = useBankAccountStore()
    const sendMoneyStore = useSendMoneyStore()

    const userProfile = authStore.userProfile

    const billingAddress = reactive({
      firstName: userProfile.firstName,
      lastName: userProfile.lastName,
      country: userProfile.country,
      street: userProfile.street,
      city: userProfile.city,
      state: userProfile.state,
      postalCode: userProfile.postalCode,
    })

    let plaidInstance
    const isPlaidInFlow = ref(false)

    const animationClass = useTransition('blur', isPlaidInFlow)

    const addBankAccountPlaid = reactive(
      usePromiseLazy((metadata) => {
        return bankAccountStore.addBankAccountPlaid({
          accountId: metadata.account.id,
          accountType: metadata.account.subtype,
          billingAddress: billingAddress,
          maskedAccountNumber: metadata.account.mask,
          bankName: metadata.institution.name,
          plaidMetaData: metadata,
        })
      })
    )

    let email = authStore.user.customer.email
    let recipientCountry = sendMoneyStore.getRecipient.country

    const showPlaid = async () => {
      analyticsStore.track({
        event: SEGMENT_EVENTS.BANK_VERIFICATION_STARTED,
        traits: {
          location: analyticsStore.paymentLocation,
          paymentVerificationMethod: 'Plaid',
          email: email,
          recipientCountry: recipientCountry,
          xemt: false,
        },
      })
      isPlaidInFlow.value = true
    }
    const onPlaidSuccess = async (public_token, metadata) => {
      // the event is trigger if we get a success verification through plaid
      // this event its not related to add the bank account to our db
      analyticsStore.track({
        event: SEGMENT_EVENTS.BANK_VERIFICATION_SUCCESS,
        traits: {
          location: analyticsStore.paymentLocation,
          paymentVerificationMethod: 'Plaid',
        },
      })

      // Remove the inflow check here since we will navigate away from the success event,
      // there are router guards that will prevent navigation if this is still set at true
      isPlaidInFlow.value = false
      // Add the account to the API
      await addBankAccountPlaid.exec(metadata)
      if (addBankAccountPlaid.error) {
        appStore.logException({
          text: 'Exception during adding bank account',
          exception: addBankAccountPlaid.error,
        })
        switch (addBankAccountPlaid.error.errorCode) {
          case 'ClientBankAccountNumberDuplicated':
            appStore.messageBoxGenericOk(
              $t('ComponentAddPaymentBankModal.Notifications.BankAccountAlreadyExists').value
            )
            break
          case 'ClientBankAccountLimitReached':
            showLimitReached.value = true
            break
          default:
            appStore.messageBoxGenericError()
        }
      } else {
        const { bankAccount } = addBankAccountPlaid.result
        if (router.currentRoute.meta.continueSendFlow) {
          // IF SMF, set payment method and go to summary
          sendMoneyStore.form.paymentMethodId = bankAccount.id
          sendMoneyStore.setPaymentMethodName({
            paymentMethodTitle: bankAccount.bankName,
            paymentMethodCaption: bankAccount.maskedAccountNumber,
            paymentMethodText: bankAccount.bankName,
            paymentMethodNumber: bankAccount.maskedAccountNumber,
          })
          router.replace({ name: STEPS.SEND_MONEY_SUMMARY })
        } else {
          // Else go to parent
          router.toParentRoute()
        }
        alerter.add($t('ComponentAddPaymentBankModal.Notifications.BankAccountAdded').value)
      }
      isPlaidInFlow.value = false
    }
    const onPlaidExit = (err) => {
      if (err) {
        appStore.logException('Exception during adding bank account', err)
      }
      isPlaidInFlow.value = false
    }

    const onEvent = (eventName, metadata) => {
      if (eventName === 'ERROR' && metadata && metadata?.error_code === 'INVALID_CREDENTIALS') {
        let email = authStore.user.customer.email
        let recipientCountry = sendMoneyStore.getRecipient.country

        analyticsStore.track({
          event: SEGMENT_EVENTS.BANK_VERIFICATION_FAILED,
          traits: {
            email: email,
            recipientCountry: recipientCountry,
            location: analyticsStore.paymentLocation,
            paymentVerificationMethod: 'Plaid',
            xemt: false,
          },
        })
      }
    }
    const manualCreate = async () => {
      analyticsStore.track({
        event: SEGMENT_EVENTS.BANK_VERIFICATION_STARTED,
        traits: {
          location: analyticsStore.paymentLocation,
          paymentVerificationMethod: 'Manual',
        },
      })
      router.push(`add-bank-account`)
    }

    onBeforeRouteLeave((to, from, next) => {
      if (isPlaidInFlow.value) {
        isPlaidInFlow.value = false
        next(false)
      } else {
        next()
      }
    })

    const showLimitReached = ref(false)

    return {
      manualCreate,
      showPlaid,
      billingAddress,
      model,
      mq: reactive(useMediaQuery()),
      $t,
      plaidInstance,
      isPlaidInFlow,
      animationClass,
      addBankAccountPlaid,
      onPlaidSuccess,
      onPlaidExit,
      onEvent,
      showLimitReached,
    }
  },
}
</script>

<style scoped>
.plaid-spinner {
  @apply block absolute;
}
</style>
